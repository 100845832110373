.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  position: absolute;
  .container-navbar {
    width: 30%;
    background-color: #bd9e24;
    @media (max-width: 768px) {
      width: 100%;
    }
    .menu-website {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      text-align: center;
      width: 100%;
      @media (max-width: 768px) {
        margin-top: 0;
        width: 100%;
      }
      .dropdown {
        position: relative;
        display: inline-block;

        .dropbtn {
          font-size: 18px;
          border: none;
          outline: none;
          color: #ffffff;
          background-color: inherit;
          font-family: "Poppins";
          font-weight: 500;
          &:hover {
            color: #14345b;
          }
        }
      }

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f8f8f8;
        min-width: 120px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
      }

      .dropdown:hover .dropdown-content {
        display: block;
      }

      .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }

      .dropdown-content a:hover {
        background-color: #ddd;
      }

      a {
        text-decoration: none;
        color: #ffffff;
        font-family: "Poppins";
        font-weight: 500;
        text-align: center;
        font-size: 18px;
        &:hover {
          color: #14345b;
          border-color: #14345b;
          border-style: solid;
          border-width: 0px 0px 3px 0px;
          padding-bottom: 10px;
        }
      }
      ul {
        padding-inline-start: 0px;
        @media (max-width: 768px) {
          padding-inline-start: 0px;
        }
      }
      ul li {
        display: inline-block;
        list-style-type: none;
        padding: 20px;
        text-align: center;
      }
    }
  }
}
