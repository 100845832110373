.khione1 {
  width: 100%;
  //margin-top: 150px;
  background-color: #53593d;
  justify-content: center;
  align-items: center;
  display: flex;

  .container-khione {
    width: 80%;
    background-image: url(../../asset/Desktop2.webp);
    background-size: contain;
    @media (max-width: 768px) {
      width: 95%;
    }
    .header-khione {
      width: 100%;
      .judul-khione {
        width: 100%;
        margin-top: 50px;
        text-align: center;
        .logok {
          width: 20%;
          //margin-bottom: 70px;
          @media (max-width: 768px) {
            width: 60%;
          }
        }
      }
    }
    .produk-khione {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 100px;
      margin-top: 30px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
    .kartu1 {
      width: 410px;
      margin-left: 15px;
      margin-top: 10px;
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0 0 10px 0;
      hr {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 7px;
        width: 90%;
      }
      @media (max-width: 768px) {
        width: 95%;
        margin-bottom: 20px;
        margin-left: 0px;
        height: auto;
        padding-bottom: 10px;
      }
      .kartugambar {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
      .kartugambar1 {
        width: 100%;
      }
      .slick-slider {
        width: 100%;
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-list {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */
          bottom: 10px; /* Adjust vertical positioning */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }

      .container-title-card {
        width: 100%;
        margin-top: 0px;
        display: flex;
        justify-content: center;

        .titlecard {
          width: 100%;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .judul-rumah1 {
            padding-left: 10px;
            color: #292929;
            font-family: "Poppins";
            font-size: 30px;
            font-weight: 600;
            align-self: flex-start;
          }
          .sub-judul-rumah {
            font-size: 16px;
            font-weight: 400;
            margin-left: 10px;
            margin-bottom: 5px;
            align-self: flex-start;
          }
        }
        .cicilan {
          padding: 10px;
          width: 100%;
          background-color: #292929;
          //padding: 10px;
          border-radius: 0 0px 10px 10px;
          color: white;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .start {
            padding-left: 10px;
            width: 50%;
            flex-direction: row;
            justify-content: left;
            font-family: "Roboto";
            font-weight: 600;
            font-size: 24px;
            @media (max-width: 768px) {
              font-size: 20px;
            }
          }
          .det {
            flex-direction: column;
            width: 100%;
            .angka {
              font-family: "Roboto";
              font-weight: 600;
              font-size: 53px;
              text-align: center;
            }
            .ket {
              font-family: "Roboto";
              font-weight: 600;
              font-size: 16px;
              text-align: center;
            }
          }
        }
      }
      .gridspek {
        background-color: #131c5a1f;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        color: #292929;
        @media (max-width: 768px) {
          padding: 15px 0px;
        }
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 5px;
          padding-right: 20px;
          @media (max-width: 768px) {
            padding-right: 20px;
            font-size: 14px;
          }
        }
      }
      .containerwhatsapp {
        display: flex;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 10px;
        .whatsapp {
          display: inline-block;
          padding: 10px 100px;
          cursor: pointer;
          border: none;
          border-radius: 5px;
          background-color: #292929; /* WhatsApp green */
          color: white;
          font-size: 16px;
        }
      }
    }
  }
}
