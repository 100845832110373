@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

.concept {
  margin-top: 150px;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
  .container-concept {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .container1 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media (max-width: 768px) {
        width: 95%;
      }
      .keterangan-concept {
        width: 80%;
        @media (max-width: 768px) {
          width: 95%;
        }
        .judul {
          text-align: left;
          color: #bd9e24;
          font-family: "Rubik";
          font-size: 45px;
          font-weight: 500;
          @media (max-width: 768px) {
            font-size: 30px;
          }
        }
      }
      .container-content {
        margin-top: 50px;
        width: 80%;
        // padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          width: 95%;
          flex-direction: column;
          border-radius: 0px;
        }
        .carousel-concept {
          width: 60%;
          display: flex;
          justify-content: left;
          align-items: center;
          @media (max-width: 768px) {
            width: 100%;
            flex-direction: column;
            border-radius: 0px;
          }
          .slick-slider {
            width: 100%;
            .slick-next::before,
            .slick-prev::before {
              font-family: "slick";
              font-size: 30px;
            }
            .slick-list {
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
              @media (max-width: 768px) {
                border-radius: 0px;
              }
            }
            .slick-track {
              display: flex;
              justify-content: center;
              align-items: center;
              align-self: center;
            }

            .slick-dots {
              position: absolute; /* Make dots absolute for positioning within slides */
              bottom: 10px; /* Adjust vertical positioning */
              left: 50%; /* Center dots horizontally */
              transform: translateX(-50%); /* Center dots precisely */
              z-index: 1; /* Ensure dots are above image content */
            }
            .slick-next {
              right: 25px;
            }
            .slick-prev {
              z-index: 1;
              left: 25px;
            }
            .slick-dots {
              li {
                margin: 0;
              }
            }
          }
          .kartugambar {
            width: 50%;
            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }
        .image-masterplan {
          width: 60%;
          height: auto;
          text-align: right;
          @media (max-width: 768px) {
            width: 100%;
          }
          .masterplan {
            width: 100%;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            @media (max-width: 768px) {
              border-radius: 0px;
            }
          }
        }
      }
    }
    .container2 {
      margin-top: 90px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .container-tod {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          width: 80%;
          flex-direction: column;
        }
        .keterangan-tod {
          width: 100%;
          .judultod {
            width: 100%;
            font-size: 30px;
            font-family: "Rubik";
            color: rgb(67, 67, 67);
            @media (max-width: 768px) {
              font-size: 25px;
              margin-bottom: 5px;
            }
          }
          .desktod {
            width: 88%;
            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }
        .carousel-tod {
          width: 50%;
          @media (max-width: 768px) {
            margin-top: 20px;
            width: 120%;
          }
          .kartugambartod {
            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }
    .container3 {
      margin-top: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .container-pod {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          width: 80%;
          flex-direction: column;
        }
        .keterangan-pod {
          width: 100%;
          .judulpod {
            width: 100%;
            font-size: 30px;
            font-family: "Rubik";
            color: rgb(67, 67, 67);
            @media (max-width: 768px) {
              font-size: 25px;
              margin-bottom: 5px;
            }
          }
          .deskpod {
            width: 88%;
            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }
        .carousel-pod {
          width: 50%;
          @media (max-width: 768px) {
            margin-top: 20px;
            width: 120%;
          }
          .kartugambartod {
            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
