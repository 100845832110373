@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

.produk {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .container-produk {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: 95%;
    }
    .container-desk {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .judul {
        font-size: 76px;
        text-align: center;
        font-family: "Rubik";
        @media (max-width: 768px) {
          font-size: 55px;
        }
      }
      .desk-judul {
        width: 75%;
        font-size: 14px;
        text-align: center;
        font-family: "Rubik";
        @media (max-width: 768px) {
          width: 95%;
        }
      }
    }
    .clusterbutton {
      margin-bottom: 60px;
      margin-top: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .nerin {
        margin-left: 20px;
        margin-right: 20px;
        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
        .button1 {
          padding: 65px 120px;
          cursor: pointer;
          background-color: white;
          border-width: 0px;
          box-shadow: 0 0 10px 0 rgb(182, 182, 182);
          border-radius: 5px;
          font-size: 0px;
          font-family: "Rubik";
          background-image: url(../../asset/nerin.webp);
          background-size: cover;
          color: #bd9e2400;
          font-size: 16px;
          &:hover {
            font-size: 16px;
            padding: 65px 120px;
            background-image: none;
            transition: 0.5s;
            color: #bd9e24;
          }
        }
      }
      .khione {
        margin-right: 20px;
        margin-left: 20px;
        .button {
          padding: 65px 120px;
          cursor: pointer;
          background-color: white;
          border-width: 0px;
          box-shadow: 0 0 10px 0 rgb(182, 182, 182);
          border-radius: 5px;
          font-size: 0px;
          font-family: "Rubik";
          background-image: url(../../asset/khione.webp);
          background-size: cover;
          color: #bd9e2400;
          font-size: 16px;
          &:hover {
            font-size: 16px;
            padding: 65px 120px;
            background-image: none;
            transition: 0.5s;
            color: #bd9e24;
          }
        }
      }
    }
    .keterangan {
      width: 70%;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgb(182, 182, 182);
      font-size: 40px;
      text-align: center;
      font-family: "Rubik";
      color: #bd9e24;
      @media (max-width: 768px) {
        width: 95%;
        font-size: 25px;
        font-weight: 500;
      }
    }
  }
}
