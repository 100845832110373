@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

.about {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
  .container-about {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 80px;
    @media (max-width: 768px) {
      width: 95%;
      margin-top: 20px;
    }
    .deskripsi {
      width: 80%;
      padding: 20px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

      .judul-about {
        font-family: "Rubik";
        font-size: 35px;
        font-weight: 600;
        margin-bottom: 10px;
        @media (max-width: 768px) {
          text-align: center;
          display: flex;
          flex-direction: column;
          font-size: 25px;
          line-height: 1.5;
        }
      }
      .button-whatsapp {
        display: flex;
        justify-content: left;
        padding-top: 25px;
        .whatsapp {
          box-shadow: 1px 1px 5px 1px rgb(93, 93, 93);
          display: inline-block;
          padding: 10px 100px;
          cursor: pointer;
          border: none;
          border-radius: 5px;
          background-color: #bd9e24; /* WhatsApp green */
          color: white;
          font-size: 16px;
          &:hover {
            background-color: white;
            color: #bd9e24;
          }
        }
      }
    }
    .slider-fasilitas {
      width: 120%;
      @media (max-width: 768px) {
        width: 100%;
      }
      .fasilitas {
        margin-top: 50px;
        .slick-dots {
          width: 100%;
        }
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }

        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }
        .image-logo {
          margin: auto;
          width: 98%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
          @media (max-width: 768px) {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
