@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

.lokasi {
  margin-top: 150px;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
  .container-lokasi {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .keterangan-lokasi {
      width: 100%;
      .judul-lokasi {
        text-align: center;
        font-family: "Rubik";
        font-size: 60px;
        color: #bd9e24;
        font-weight: 500;
      }
    }
    .benefit-container {
      display: flex;
      flex-direction: row;
      margin-top: 25px;
      justify-content: center;
      @media (max-width: 768px) {
        flex-direction: column;
      }

      .benefit-point {
        color: #535353;
        padding-right: 45px;
        @media (max-width: 768px) {
          flex-direction: column;
          padding-right: 0;
          padding-left: 25px;
        }
        .benefit-title {
          font-family: "Robbotto", Sans-serif;
          font-size: 40px;
          font-weight: 600;
          font-size: 30px;
          padding: 10px 25px 25px 0;
        }
        .benefit {
          font-size: 18px;
        }
      }
    }
    .image-lokasi-container {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 50px;
      box-shadow: 0 0 10px 0;
      .imagelokasi {
        width: 100%;
        background-image: url(../../asset/maps-dekstop.webp);
        background-size: cover;
        background-position: center;
        height: 70vh;
        @media (max-width: 768px) {
          background-image: url(../../asset/maps-mobile.webp);
          height: 60vh;
        }
      }
    }
  }
}
